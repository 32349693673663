import React, { useState, useEffect } from 'react';
import CurrencyGraph from './currencyGraph';
import './side-box.css';
import Web3 from 'web3'; // Import web3 library

const SideBox = ({ account, currencyData, handleTimeFrameChange }) => {
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        // Initialize Web3 and the contract when the component mounts
        async function initializeWeb3() {
            if (window.ethereum) {
                try {
                    // Request account access if needed
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    const provider = new Web3(window.ethereum); // Use Web3 provider from window.ethereum
                    setWeb3(provider);

                    // Replace 'YourContractAddress' and 'YourContractAbi' with your actual contract details
                    const contractAddress = '0xCBfA8207A3c4467670e940800e3297B8aDA57D7F';
                    const contractAbi = [{
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "systemContractAddress",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "nonpayable",
                        "type": "constructor"
                    },
                    {
                        "inputs": [
                            {
                                "components": [
                                    {
                                        "internalType": "bytes",
                                        "name": "origin",
                                        "type": "bytes"
                                    },
                                    {
                                        "internalType": "address",
                                        "name": "sender",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "chainID",
                                        "type": "uint256"
                                    }
                                ],
                                "internalType": "struct zContext",
                                "name": "context",
                                "type": "tuple"
                            },
                            {
                                "internalType": "address",
                                "name": "zrc20",
                                "type": "address"
                            },
                            {
                                "internalType": "uint256",
                                "name": "amount",
                                "type": "uint256"
                            },
                            {
                                "internalType": "bytes",
                                "name": "message",
                                "type": "bytes"
                            }
                        ],
                        "name": "onCrossChainCall",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "systemContract",
                        "outputs": [
                            {
                                "internalType": "contract SystemContract",
                                "name": "",
                                "type": "address"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    }];

                    const contractInstance = new web3.eth.Contract(contractAbi, contractAddress); // Use web3.eth.Contract
                    setContract(contractInstance);
                } catch (error) {
                    console.error('Error connecting to Web3:', error);
                }
            } else {
                console.error('Web3 not found. Please install MetaMask or another Ethereum wallet.');
            }
        }

        initializeWeb3();
    }, []);

    const buyTokens = async () => {
        try {
            // Replace 'buy' with your actual buy function in the contract
            const tx = await contract.methods.buy().send({ from: account }); // Call method using contract.methods
            console.log('Tokens bought successfully!');
        } catch (error) {
            console.error('Error buying tokens:', error);
        }
    };

    const sellTokens = async () => {
        try {
            // Replace 'sell' with your actual sell function in the contract
            const tx = await contract.methods.sell().send({ from: account }); // Call method using contract.methods
            console.log('Tokens sold successfully!');
        } catch (error) {
            console.error('Error selling tokens:', error);
        }
    };

    return (
        <div className="side-box">
            <div className="currencyGraph">
                <CurrencyGraph chartData={currencyData} />
                <div className="time-frame-buttons">
                    <button onClick={() => handleTimeFrameChange('1D')}>One Day</button>
                    <button onClick={() => handleTimeFrameChange('1W')}>One Week</button>
                    <button onClick={() => handleTimeFrameChange('1M')}>One Month</button>
                    <button onClick={() => handleTimeFrameChange('3M')}>Three Months</button>
                </div>
            </div>
            <div className="content">
                <h2>ZETA</h2>
                <div className="currency-select">
                    <span>{currencyData.name}</span>
                    <button>MAX</button>
                </div>
                <div className="currency-info">
                    <p>Current Value: ${currencyData.value.toFixed(2)}</p>
                </div>
                <div className="actions">
                    <button className="buy" onClick={buyTokens}>Purchase</button>
                    <button className="short" onClick={sellTokens}>Short Sell</button>
                </div>
                <div className="warning">
                    <strong>Funding Rate (1D): +0.0068%</strong>
                    <p>The funding rate determines whether you lose or gain funding on an open position.</p>
                </div>
                <div className="preview">
                    <button>Preview</button>
                </div>
                <div className="details">
                    <div className="detail">
                        <span>Total Shareholders</span>
                        <span>587,695</span>
                    </div>
                    <div className="detail">
                        <span>Conversion Rate</span>
                        <span>1.044</span>
                    </div>
                    <div className="detail">
                        <span>Estimated Gas</span>
                        <span>0.054 aZETA</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBox;
